import Service from "./Service";
const baseurl = '/api/examen-estomatologico-usuario';
import { isEmpty } from "lodash";
export default {
    indexTejidoUsuario(id, pagination = {},search=''){
        let url = `${baseurl}/tejidos-blandos-by-usuario/${id}?`;
        
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    storeTejidoUsuario(obj) {
        return Service.post(`${baseurl}/store-tejidos-usuario`, obj);
    },
    indexBatmUsuario(id, pagination = {},search=''){
        let url = `${baseurl}/batm-by-usuario/${id}?`;
        
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    storeBatmUsuario(obj) {
        return Service.post(`${baseurl}/store-batm-usuario`, obj);
    },
    indexAlteracionPeriodontalUsuario(id, pagination = {},search=''){
        let url = `${baseurl}/alteracion-periodontal-by-usuario/${id}?`;
        
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    storeAlteracionPeriodontalUsuario(obj) {
        return Service.post(`${baseurl}/store-alteraciones-periodontales-usuario`, obj);
    },
    storeExamenPulparUsuario(obj) {
        return Service.post(`${baseurl}/store-examen-pulpar-usuario`, obj);
    },
    examenPulparByHc($id){
        return Service.get(`${baseurl}/examen-pulpar-by-hc/${$id}`);
    },
    storeAnalisisRadiografico(obj) {
        return Service.post(`${baseurl}/store-analisis-radiografico`, obj);
    },
    analisisRadiograficoByHc($id){
        return Service.get(`${baseurl}/analisis-radiografico-by-hc/${$id}`);
    }
}