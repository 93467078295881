<template>
    <main>
      <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
      <page-header-compact>Historia Clinica - Odontología</page-header-compact>
      <br />
      <div class="container-xl px-4">
        <div class="card ">
          <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
        </div>
        <br />
        <wizard
            :steps="steps.length"
            @prev="prev($event)"
            @next="next($event)"
            :current_step="current"
        >
          <template #tabs>
            <wizard-tab
                v-for="(component, i) in steps"
                :key="`tabs_${i}`"
                :title="component.title"
                :step="i+1"
                :active="(i+1)===current"
                :id="`step${i+1}`"
                v-on:tab-click="next({ prev: current, current: (i+1) })"
            >
            </wizard-tab>
          </template>
          <template #tab-content>
            <wizard-tab-pane id="step1" active="true">
              <template #content>
                <component
                    :is="currentComponent.ref"
                    :ref="currentComponent.ref"
                    v-bind="currentProperties"
                ></component>
              </template>
            </wizard-tab-pane>
          </template>
          <template #finish>
            <div class="row">
              <remision-component :idHistoria="idHistoria" :usuario="usuario"></remision-component>
              <finalizar-historia :id-usuario="usuario.id" :id-historia="idHistoria" :codigoHistoria="'MORBILIDAD'"></finalizar-historia>
            </div>
          </template>
        </wizard>
      </div>
    </main>
  </template>
  <script>
  
  import Wizard from "../../../components/common/utilities/Wizard/Wizard";
  import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
  import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
  import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
  import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
  import historiaClinicaService from "../../../services/historiaClinicaService";
  import ordenComponent from "../base/orden/ordenComponent";
  import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
  import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
  import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
  import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
  import recomendaciones from "@/views/historias/base/recomendaciones/recomendaciones";
  import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
  import embarazoService from "@/services/embarazoService";
  import dayjs from "dayjs";
  //import {isEmpty} from "lodash";
  import RemisionComponent from "../base/baseRemision/index";
  import antecedentesOdontologicos from "@/views/historias/base/baseAntecedentesOdontologicos/index";
  import habitosOrales from "../base/baseHabitosOrales/index";
  import higieneOral from "../base/baseHigieneOral/index";
  import odontograma from "../base/baseOdontograma/index";
  import odontogramaProcedimientos from "../base/baseOdontogramaProcedimientos/index";
  import controlPlaca from "../base/baseControlPlaca/index";
  import tejidosBlandos from "../base/baseExamenEstomatologico/tejidosBlandos";
  import batm from "../base/baseExamenEstomatologico/batm";
  import alteracionesPeriodontales from "../base/baseExamenEstomatologico/alteracionPeriodontal";
  import examenPulpar from "../base/baseExamenEstomatologico/examenPulpar";
  import analisisRadiografico from "../base/baseExamenEstomatologico/analisisRadiografico";
  import sesionesTratamiento from "../base/baseSesionesTratamiento/index";
  import intervenciones from "@/views/historias/base/intervenciones/intervenciones";
  import notaOdontologica from "../base/baseNotaOdontologica/notaOdontologica";
  export default {
    components: {
      FinalizarHistoria,
      PageHeaderCompact,
      motivoConsultaComponent,
      WizardTabPane,
      WizardTab,
      Wizard,
      ordenComponent,
      formulaMedicaComponent,
      impresionDXComponent,
      modalInformacionUsuario,
      cardInformacionUsuario,
      recomendaciones,
      RemisionComponent,
      antecedentesOdontologicos,
      habitosOrales,
      odontograma,
      controlPlaca,
      odontogramaProcedimientos,
      higieneOral,
      tejidosBlandos,
      batm,
      alteracionesPeriodontales,
      examenPulpar,
      analisisRadiografico,
      sesionesTratamiento,
      intervenciones,
      notaOdontologica
    },
    data() {
      return {
        finalizada: '',
        idHistoria: '',
        idTipoHistoria : '',
        usuario: {},
        ultimoEmbarazo : {},
        current: 1,
        checkpoint : 1,
      };
    },
    methods: {
      prev(step) {
        this.current = step.current;
        this.activeTab();
      },
      async next(step) {
  
        if(step.prev <= this.checkpoint && step.current-1 > step.prev){
          this.activeTab();
          return;
        }
  
        const refComponent = this.steps[step.prev - 1].ref;
  
        if (typeof this.$refs[refComponent].save !== "undefined") {
          await this.$refs[refComponent].save();
        }
  
        if (this.steps[step.prev - 1].validate) {
          if (this.$refs[refComponent].$v.$invalid) return;
        }
  
        if(step.current > this.checkpoint) {
          historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
          this.checkpoint = step.current;
        }
  
        this.current = step.current;
  
        this.activeTab();
      },
      async cargar(id) {
  
        const response = await historiaClinicaService.show(id);
        this.usuario = response.data.usuario;
        this.finalizada = response.data.finalizada;
        //this.current = response.data.checkpoint;
        //this.checkpoint = response.data.checkpoint;
                                                                               
        this.idTipoHistoria = response.data.id_tipo_historia;
       
        this.activeTab();
  
        this.ultimoEmbarazo = (await embarazoService.showByLast(this.usuario.id))?.data;
  
        this.current = response.data.checkpoint;
        this.checkpoint = response.data.checkpoint;
      },
      activeTab(){
        document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
        document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
      }
    },
    created() {
      this.idHistoria = this.$route.params.id;
      this.cargar(this.idHistoria);
    },
    computed: {
      currentProperties() {
  
        if (this.currentComponent.ref === "motivoConsultaComponent") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
          }
        }
  
        if (this.currentComponent.ref === "antecedentesOdontologicos") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "habitosOrales") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "higieneOral") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "tejidosBlandos") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "batm") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "alteracionesPeriodontales") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "examenPulpar") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "analisisRadiografico") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "odontograma") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "odontogramaProcedimientos") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "controlPlaca") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            idUsuario:this.usuario.id,
            usuario:this.usuario,
            embarazo_actual:this.ultimoEmbarazo
          }
        }
  
        if (this.currentComponent.ref === "ordenComponent") {
          return {
            idHistoria: this.idHistoria,
            usuario : this.usuario,
          }
        }
  
        if (this.currentComponent.ref === "formulaMedicaComponent") {
          return {
            idHistoria: this.idHistoria,
            usuario : this.usuario,
          }
        }
        if (this.currentComponent.ref === "impresionDXComponent") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
          }
        }
  
        if (this.currentComponent.ref === "sesionesTratamiento") {
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            usuario : this.usuario,
            idTipoHistoria: this.idTipoHistoria
          }
        }
  
        if(this.currentComponent.ref === "notaOdontologica"){
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            usuario : this.usuario.sexo,
            idTipoHistoria: this.idTipoHistoria
          }
        }

        if(this.currentComponent.ref === "intervenciones"){
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            usuario : this.usuario.sexo,
            idTipoHistoria: this.idTipoHistoria
          }
        }
  
        if(this.currentComponent.ref === "recomendaciones"){
          return {
            idHistoria: this.idHistoria,
            finalizada: this.finalizada,
            usuario : this.usuario.sexo,
            idTipoHistoria: this.idTipoHistoria
          }
        }
  
        return {}
      },
      currentComponent() {
        return this.steps[this.current - 1];
      },
      steps(){
        let components = [
          { ref: "motivoConsultaComponent", title:"Motivo de Consulta",validate: true },
          /* { ref: "antecedentesOdontologicos", title:"Antecedentes Odontologicos", validate: true }, */
          { ref: "habitosOrales", title:"Habitos Orales", validate: true },
          { ref: "higieneOral", title:"Higiene Oral", validate: true },/* 
          { ref: "tejidosBlandos", title:"Tejidos Blandos", validate: true },
          { ref: "batm", title:"A.T.M", validate: true },
          { ref: "alteracionesPeriodontales", title:"Alteraciones Periodontales", validate: true },
          { ref: "examenPulpar", title:"Examen Pulpar", validate: false },
          { ref: "analisisRadiografico", title:"Analisis radiografico", validate: false }, */
          { ref: "odontograma", title:"Odontograma",validate: false },
          { ref: "odontogramaProcedimientos", title:"Odontograma Procedimiento",validate: false },
          { ref: "controlPlaca", title:"Control Placa",validate: false },
          { ref: "ordenComponent", title:"Ordenes",validate: false },
          { ref: "formulaMedicaComponent",title:"Tratamiento",validate: false },
          { ref: "impresionDXComponent",title:"Impresión DX",validate: true },
          { ref: "notaOdontologica",title:"Nota Odontologica",validate: false },
          { ref: "intervenciones",title:"Plan de Intervención",validate: false },
          { ref: "recomendaciones",title:"Recomendaciones",validate: false },
          /* { ref: "sesionesTratamiento",title:"Sesiones",validate: false }, */
        ];
  
        /* if(this.edad >= 0 && this.edad <= 5){
          components.push({ ref: "index", title:"AIEPI",validate: false });
        }else if(!isEmpty(this.ultimoEmbarazo)){
          if(this.ultimoEmbarazo.activo === 'S'){
            components.push({ ref: "index", title:"AIEPI",validate: false });
          }
        } */
        
        return components.map((x,i) => {
          return {
            stepId : `#step${i+1}`,
            ...x,
          }
        });
      },
      edad: function() {
        const now = dayjs();
        return now.diff(this.usuario.fecha_nacimiento, "years");
      },
    },
  }
  </script>
  <style scoped></style>                                                                                                                                                                                                                                                                                                                              
  