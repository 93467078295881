<template>
    <div class="p-0">
        <v-layer ref="konvaLayer">
            <faceOne :config="config_face_one" :teeth="teeth" @selected="onSelectedFace" :appliedConventions="perFaceConventions.faceOne"></faceOne>
            <faceTwo :config="config_face_two" :teeth="teeth" @selected="onSelectedFace" :appliedConventions="perFaceConventions.faceTwo"></faceTwo>
            <faceThree :config="config_face_three" :teeth="teeth" @selected="onSelectedFace" :appliedConventions="perFaceConventions.faceThree"></faceThree>
            <faceFour :config="config_face_four" :teeth="teeth" @selected="onSelectedFace" :appliedConventions="perFaceConventions.faceFour"></faceFour>
            <faceFive :config="config_face_five" :teeth="teeth" @selected="onSelectedFace" :appliedConventions="perFaceConventions.faceFive"></faceFive>
            <v-text :config="config_text" />
        </v-layer>
    </div>
</template>
<script>
import faceTwo from './faceTwo';
import faceThree from './faceThree';
import faceFive from './faceFive';
import faceFour from './faceFour';
import faceOne from './faceOne';

export default {
    name: "pieza",
    components: { faceOne ,faceTwo, faceThree,faceFour, faceFive },
    props: ['teeth', "conventions", 'index'],
    data() {
        return {
            config_text: {
                x: (15 + (this.index * 50)),
                y: 50,
                text: this.teeth.number,
                fontSize: 12
            },
            config_face_one: {
                x: (22 + (this.index * 50)),
                y: 22,
                radius: 10,
                fill: "transparent",
                stroke: "gray",
                strokeWidth: 1,
            },
            config_face_two: {
                x: (22 + (this.index * 50)),
                y: 22,
                innerRadius: 10, // Reduce el radio interior
                outerRadius: 20, // Reduce el radio exterior
                angle: 90,
                fill: "transparent",
                stroke: "gray",
                strokeWidth: 1,
                rotation: 225,
            },
            config_face_three: {
                x: (22 + (this.index * 50)),
                y: 22,
                innerRadius: 10, // Reduce el radio interior
                outerRadius: 20, // Reduce el radio exterior
                angle: 90,
                fill: "transparent",
                stroke: "gray",
                strokeWidth: 1,
                rotation: 315,
            },
            config_face_four: {
                x: (22 + (this.index * 50)),
                y: 22,
                innerRadius: 10, // Reduce el radio interior
                outerRadius: 20, // Reduce el radio exterior
                angle: 90,
                fill: "transparent",
                stroke: "gray",
                strokeWidth: 1, // Puedes ajustar el grosor del trazo
                rotation: 45,
            },
            config_face_five: {
                x: (22 + (this.index * 50)),
                y: 22,
                innerRadius: 10, // Reduce el radio interior
                outerRadius: 20, // Reduce el radio exterior
                angle: 90,
                fill: "transparent",
                stroke: "gray",
                strokeWidth: 1,
                rotation: 135,
            }
        };
    },
    methods : {
        onSelectedFace($eventInfo){
           this.$emit('selectedFace', $eventInfo);
        }
    },
    computed : {
        perFaceConventions(){
            return {
                "faceOne" : this.conventions.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F1'),
                "faceTwo" : this.conventions.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F2' && x.conventionInfo.type == 'single'),
                "faceThree" : this.conventions.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F3' && x.conventionInfo.type == 'single'),
                "faceFour" : this.conventions.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F4' && x.conventionInfo.type == 'single'),
                "faceFive" : this.conventions.filter(x => x.faceInfo.dentNumber == this.teeth.number && x.faceInfo.faceCode == 'F5' && x.conventionInfo.type == 'single'),
            }
        }
    }
};
</script>
