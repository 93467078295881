<template>
  <div class="col-12">
    <div class="card shadow-none mt-2">
      <div class="card-header text-center bg-light">Dientes Permanentes</div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6" v-for="(item, index) in permanentTeethByQuadrant" :key="index">
            <div class="row p-0">
              <v-stage :config="configKonva" :ref="'stagePermanent' + index">
                <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                  <div class="justify-content-between p-0 ">
                    <TeethComponent :index="index" :teeth="teeth" @selectedFace="onSelectedFace"
                      @deselectedFace="onDeselectedFace" :apliedFill="apliedFill"></TeethComponent>
                  </div>
                </div>
              </v-stage>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-none mt-2">
      <div class="card-header text-center bg-light">Dientes Temporaes</div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6" v-for="(item, index) in temporaryTeethByQuadrant" :key="index">
            <div class="row p-0">
              <v-stage :config="configKonva" :ref="'stageTemporary' + index">
                <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                  <div class="justify-content-between p-0 ">
                    <TeethComponent :index="index" :teeth="teeth" @selectedFace="onSelectedFace"
                      @deselectedFace="onDeselectedFace" :apliedFill="apliedFill"></TeethComponent>
                  </div>
                </div>
              </v-stage>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3">
        <div class="card shadow-none">
          <div class="card-header">Dientes Ausentes</div>
          <div class="card-body text-center mb-0">
            <h2 class="text-bold mb-0">{{ teeth_bad }}</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card shadow-none">
          <div class="card-header">Total Caras</div>
          <div class="card-body text-center mb-0">
            <h2 class="text-bold mb-0">{{ total_faces }}</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card shadow-none">
          <div class="card-header">Control</div>
          <div class="card-body text-center mb-0">
            <h2 class="text-bold mb-0">{{ total_faces_selected }}</h2>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card shadow-none">
          <div class="card-header">Porcentaje</div>
          <div class="card-body text-center mb-0">
            <h2 :class="clasification_percentage.text">{{ percentage }} %</h2>
            <span :class="clasification_percentage.class">{{ clasification_percentage.detail }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import TeethComponent from "./teeth.vue";
import odontogramaAdulto from "@/assets/odontograma/odontogramaAdulto.json";
import Toast from '../../../../components/common/utilities/toast';
import historiaOdontogramaService from "@/services/historiaOdontogramaService";
import { isEmpty } from 'lodash';
import historiaControlPlacaService from "../../../../services/historiaControlPlacaService";
export default {
  components: {
    TeethComponent
  },
  props: ['embarazo_actual', 'usuario', 'idHistoria', 'finalizada', 'modo'],
  data() {
    return {
      configKonva: {
        width: 700,
        height: 80,
      },
      permanentTeeth: odontogramaAdulto.permanent,
      temporaryTeeth: odontogramaAdulto.temp,
      apliedFill: [],
      permanentTeethByQuadrant: [],
      temporaryTeethByQuadrant: [],
      teeth_bad: '',
      imgQuadrants: []
    };
  },
  methods: {
    onSelectedFace($eventInfo) {
      this.apliedFill.push({
        faceInfo: $eventInfo
      });
    },
    onDeselectedFace($eventInfo) {
      const indexToRemove = this.apliedFill.findIndex(item => item.faceInfo.dentNumber === $eventInfo.dentNumber && item.faceInfo.faceCode === $eventInfo.faceCode);
      if (indexToRemove !== -1) {
        this.apliedFill.splice(indexToRemove, 1);
      }
    },
    groupTeethsByQuadrant() {

      const groupPermanentTeeth = {};

      this.permanentTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupPermanentTeeth[quadrantKey]) {
          groupPermanentTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupPermanentTeeth[quadrantKey].teeths.push(teeth);
      });

      this.permanentTeethByQuadrant = { ...groupPermanentTeeth };

      const groupTemporaryTeeth = {};

      this.temporaryTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupTemporaryTeeth[quadrantKey]) {
          groupTemporaryTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupTemporaryTeeth[quadrantKey].teeths.push(teeth);
      });

      this.temporaryTeethByQuadrant = { ...groupTemporaryTeeth };

    },
    async getOdontograma() {

      const response = await historiaOdontogramaService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {

        let conventions = {};

        response.data.detalles.forEach(detalle => {
          let nombreConvencion = detalle.convencion.name;
          conventions[nombreConvencion] = (conventions[nombreConvencion] || 0) + 1;
        });
        console.log(conventions);
        this.teeth_bad = conventions['ausente'] ? conventions.ausente : 0;

      } else {
        this.teeth_bad = 0;
      }

    },
    downloadSVG() {
      const stages = [];
      // Iterar sobre las referencias y filtrar las que comienzan con "stagePermanent"
      for (const key in this.$refs) {
        if (key.startsWith('stagePermanent')) {
          stages.push({
            quadrant: key.replace('stagePermanent', 'perm_'),
            typeDent: 'permanent',
            stage: this.$refs[key]
          });
        }
        if (key.startsWith('stageTemporary')) {
          stages.push({
            quadrant: key.replace('stageTemporary', 'temp_'),
            typeDent: 'temporary',
            stage: this.$refs[key]
          });
        }
      }

      let stagesToSave = [];

      stagesToSave = stages.map(x => {
        const base64 = x.stage[0].getStage().toDataURL();
        return {
          quadrant: x.quadrant,
          typeDent: x.typeDent,
          binaryImg: base64,
          base64: base64,
        }
      })

      this.imgQuadrants = stagesToSave;
    },
    async save() {
      try {
        this.LoaderSpinnerShow();

        this.downloadSVG();

        let dataControlPlaca = {
          idHistoria: this.idHistoria,
          dientes_ausentes: this.teeth_bad,
          total_caras: this.total_faces,
          control: this.total_faces_selected,
          porcentaje: this.percentage,
          clasificacion: this.clasification_percentage.detail,
          apliedConventions: this.apliedFill.map(x => {
            return {
              faceCode: x.faceInfo.faceCode,
              dentNumber: x.faceInfo.dentNumber
            }
          }),
        }

        const response = await historiaControlPlacaService.store(dataControlPlaca);

        for (let index = 0; index < this.imgQuadrants.length; index++) {
          await historiaControlPlacaService.storeGraphics({
            idHistoria: this.idHistoria,
            idControl: response.data.id,
            imgQuadrants: this.imgQuadrants[index],
          });
        }
        /* const promiseArray = this.imgQuadrants.map(x => {
          return historiaControlPlacaService.storeGraphics({
            idHistoria: this.idHistoria,
            idControl: response.data.id,
            imgQuadrants: [x]
          });
        });

        await Promise.all(promiseArray); */

        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con éxito'
        });
      } catch (error) {
        console.log(error);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al procesar la solicitud'
        });
      }
    }
  },

  async mounted() {
    this.groupTeethsByQuadrant();
    this.getOdontograma();
    if (this.finalizada === 'N') {
      const response = await historiaControlPlacaService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.apliedFill = response.data.detalles.map(x => {
          return {
            faceInfo: {
              dentNumber: x.dentNumber,
              faceCode: x.faceCode
            }
          }
        })
      } 
    }
  },
  computed: {
    total_faces_selected() {
      return this.apliedFill.length;
    },
    total_faces() {
      return (32 - this.teeth_bad) * 4;
    },
    percentage() {
      return Math.round((this.total_faces_selected * 100) / this.total_faces);
    },
    clasification_percentage() {
      const casifications = [
        {
          inicio: 0,
          fin: 20,
          detail: 'Bien',
          class: 'badge badge-success badge-marketing',
          text: 'text-success'
        },
        {
          inicio: 21,
          fin: 30,
          detail: 'Regular',
          class: 'badge badge-warning badge-marketing',
          text: 'text-warning'
        },
        {
          inicio: 31,
          fin: 100,
          detail: 'Mal',
          class: 'badge badge-danger badge-marketing',
          text: 'text-danger'
        }
      ];

      const valoration = casifications.find(x => this.percentage >= x.inicio && this.percentage <= x.fin);
      if (valoration) return valoration

      return {
        inicio: 0,
        fin: 0,
        detail: 'Cargando clasificacion',
        class: 'span span-default text-danger'
      };
    },
  },
  watch: {
    finalizada: async function (value) {
      if (value === 'N') {
        const response = await historiaControlPlacaService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.apliedFill = response.data.detalles.map(x => {
            return {
              faceInfo: {
                dentNumber: x.dentNumber,
                faceCode: x.faceCode
              }
            }
          })
        }
      }
    }
  }
};
</script>
   