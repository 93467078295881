<template>
    <main>
        <div v-if="false">
            <div class="row no-gutters border-bottom mb-1" v-for="item in recomendaciones" :key="item.id">
                <div class="col-1 d-flex align-items-center justify-content-center">
                    <input type="checkbox" class="form-check-input checkbox-lg mb-4" v-model="item.seleccionado">
                </div>
                <div class="col-11 py-2">
                    <blockquote class="small m-0 mr-4">{{ item.recomendacion }}</blockquote>
                </div>
            </div>

        </div>
        <div>
            <label class="form-label">Nota Odontologica</label>
            <textarea cols="30" rows="5" class="form-control" v-model="nota"></textarea>
        </div>
    </main>
</template>
    
<script>
import Toast from "@/components/common/utilities/toast";
import { isEmpty } from "lodash";
import notaOdontologicaService from "@/services/notaOdontologicaService";

export default {
    props: ['idHistoria', 'idTipoHistoria', 'usuario', 'finalizada'],
    data() {
        return {
            recomendaciones: [],
            nota: ''
        }
    },
    methods: {
        /* async cargar() {

            const response = await recomendacionService.index({
                id_tipo_historia: this.idTipoHistoria,
            });

            this.recomendaciones = response.data.map(x => {
                return {
                    ...x,
                    seleccionado: false
                }
            });
        }, */
        async save() {
            try {

                /* const obj = {
                  id_historia : this.idHistoria,
                  recomendaciones : this.recomendaciones.filter(x=>x.seleccionado)
                };*/

                const obj = {
                    id_historia: this.idHistoria,
                    notas: this.nota
                }

                this.LoaderSpinnerShow();

                await notaOdontologicaService.store(obj);

                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exíto'
                });

            } catch (e) {
                console.log(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error al procesar la solicitud'
                });
            }

        }
    },
    watch: {
        finalizada: async function (value) {
            if (value === 'N') {
                const response = await notaOdontologicaService.showByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    this.nota = response.data.nota
                }
            }
        }
    },
    async created() {
        //await this.cargar();

        /* const response = await notaOdontologicaService.index({
          id_historia : this.idHistoria
        });
    
        if(!isEmpty(response.data)){
          response.data.forEach(x => {
            let i = this.recomendaciones.findIndex(y => y.id === x.id_recomendacion);
            if(i!==-1) this.recomendaciones[i].seleccionado = true;
          }) 
        } */
        if (this.finalizada === 'N') {
            const response = await notaOdontologicaService.showByHc(this.idHistoria);

            if (!isEmpty(response.data)) {
                this.nota = response.data.nota
            }
        }

    }
}
</script>
    
<style scoped>
.checkbox-lg {
    width: 20px;
    height: 20px;
}
</style>