<template>
  <div class="col-12">
    <div class="card shadow-none">
      <div class="card-body">
      <div class="d-block">
        <button class="btn btn-light border-blue-soft btn-xs mt-2 mx-1" :title="conventions.name" v-for="convention in conventions" :key="convention.name"
          @click="onSelectConvention(convention)">
          <svg height="20" width="20">
            <path :d="convention.svgPath" transform="scale(0.2)" :stroke="convention.fill" :fill="convention.fill" />
          </svg>
          <span style="font-size: 12px;" class="text-black mt-0 p-0">{{ convention.name }}</span>
        </button>
      </div>
    </div>
  </div>
  
   <!--  <div class="btn-group shadow-none" role="group">
      <button class="btn btn-light border-blue-soft btn-sm  justify-content-between" :title="conventions.name"
        v-for="convention in conventions" :key="convention.name" @click="onSelectConvention(convention)">
        <svg height="28" width="25">
          <path :d="convention.svgPath" transform="scale(0.5)" :stroke="convention.fill" :fill="convention.fill" />
        </svg>
        {{ convention.name }}
      </button>
    </div> -->
    <div class="card shadow-none mt-2">
      <div class="card-header text-center bg-light">Dientes Permanentes</div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6" v-for="(item, index) in permanentTeethByQuadrant" :key="index">
            <div class="row p-0">
              <v-stage :config="configKonva" :ref="'stagePermanent' + index">
                <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                  <div class="justify-content-between p-0 ">
                    <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions" @selectedFace="onSelectedFace">
                    </Pieza>
                  </div>
                </div>
              </v-stage>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-none mt-2">
      <div class="card-header text-center bg-light">Dientes Temporales</div>
      <div class="card-body ">
        <div class="row">
          <div class="col-lg-6" v-for="(item, index) in temporaryTeethByQuadrant" :key="index">
            <div class="row">
              <v-stage :config="configKonva" :ref="'stageTemporary' + index">
                <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                  <div class="justify-content-between">
                    <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions" @selectedFace="onSelectedFace">
                    </Pieza>
                  </div>
                </div>
              </v-stage>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive mt-2">
      <table class="table table-bordered mb-0 table-sm">
        <thead class="border-bottom">
          <tr class="small text-uppercase text-muted table-active">
            <th scope="col">Odontograma</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in groupTheet" :key="index">
            <div>
              <th rowspan="2" class="font-bold number-column align-middle text-center table-active">{{ item.number }}</th>
              <td class="face-column text-center table-active" scope="col" v-for="(face, index) in item.faces"
                :key="index">
                {{ face.name }}
              </td>
          <tr>
            <td class="face-column text-center" scope="col" v-for="(face, faceIndex) in item.faces" :key="faceIndex">
              <span class="badge badge-info-soft badge-marketing" v-if="buscarConvencion(item.number, face.faceCode)">
                {{ buscarConvencion(item.number, face.faceCode) }} <i
                  @click="deleteConvention(item.number, face.faceCode)"
                  class="fas fa-times-circle text-danger fa-fw cursor-pointer"></i>
              </span>
            </td>
          </tr>
        </div>
      </tr>
    </tbody>
    </table>
  </div>
  <div class="row mt-2">
    <div class="col-lg-3">
      <div class="card shadow-none">
        <div class="card-header">Cariados</div>
        <div class="card-body text-center mb-0">
          <h2 class="text-bold mb-0">{{ formatCount(totalCariados)}}</h2>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-none">
        <div class="card-header">Obturados</div>
        <div class="card-body text-center mb-0">
          <h2 class="text-bold mb-0">{{ formatCount(totalObturados) }}</h2>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-none">
        <div class="card-header">Perdidos</div>
        <div class="card-body text-center mb-0">
          <h2 class="text-bold mb-0">{{ formatCount(totalPerdidos) }}</h2>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-none">
        <div class="card-header">Sanos</div>
        <div class="card-body text-center mb-0">
          <h2 class="text-success">{{ totalSanos }}</h2>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card shadow-none">
        <div class="card-header">Indice COP</div>
        <div class="card-body text-center mb-0">
          <h2 class="text-success">{{ formatCount(totalCariados) }}{{ formatCount(totalObturados) }}{{ formatCount(totalPerdidos) }}</h2>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
  
<script>
import Pieza from "./pieza/pieza.vue"
import odontogramaAdulto from "@/assets/odontograma/odontogramaAdulto.json";
import Toast from '../../../../components/common/utilities/toast';
import convencionesOdontogramaService from '@/services/convencionesOdontogramaService';
import historiaOdontogramaService from '@/services/historiaOdontogramaService';
import { isEmpty } from 'lodash';
export default {
  components: {
    Pieza
  },
  props: ['idHistoria', 'usuario', 'finalizada'],
  data() {
    return {
      configKonva: {
        width: 400,
        height: 80,
      },
      permanentTeeth: odontogramaAdulto.permanent,
      temporaryTeeth: odontogramaAdulto.temp,
      conventions: [],
      apliedConventions: [],
      selectedConvention: null,
      permanentTeethByQuadrant: [],
      temporaryTeethByQuadrant: [],
      imgQuadrants: [],
    };
  },
  methods: {
    onSelectedFace($eventInfo) {
      if (this.selectedConvention) {
        const faceCode = this.selectedConvention.type === 'all' ? 'F1' : $eventInfo.faceCode;

        this.apliedConventions.push({
          faceInfo: {
            dentNumber: $eventInfo.dentNumber,
            faceCode: faceCode,
          },
          conventionInfo: this.selectedConvention,
          teethInfo: $eventInfo.teethInfo
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Debe seleccionar una convención'
        });
      }
    },
    onSelectConvention(conventionInfo) {
      this.selectedConvention = conventionInfo
    },
    groupTeethsByQuadrant() {

      const groupPermanentTeeth = {};

      this.permanentTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupPermanentTeeth[quadrantKey]) {
          groupPermanentTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupPermanentTeeth[quadrantKey].teeths.push(teeth);
      });

      this.permanentTeethByQuadrant = { ...groupPermanentTeeth };

      const groupTemporaryTeeth = {};

      this.temporaryTeeth.forEach((teeth) => {

        const quadrantKey = `cuadrante_${teeth.quadrant}`;

        if (!groupTemporaryTeeth[quadrantKey]) {
          groupTemporaryTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
        }

        groupTemporaryTeeth[quadrantKey].teeths.push(teeth);
      });

      this.temporaryTeethByQuadrant = { ...groupTemporaryTeeth };

    },
    downloadSVG() {
     
      const stages = [];
      // Iterar sobre las referencias y filtrar las que comienzan con "stagePermanent"
      for (const key in this.$refs) {
        if (key.startsWith('stagePermanent')) {
          stages.push({
            quadrant: key.replace('stagePermanent', 'perm_'),
            typeDent: 'permanent',
            stage: this.$refs[key]
          });
        }
        if (key.startsWith('stageTemporary')) {
          stages.push({
            quadrant: key.replace('stageTemporary', 'temp_'),
            typeDent: 'temporary',
            stage: this.$refs[key]
          });
        }
      }

      let stagesToSave = [];

      stagesToSave = stages.map(x => {
        const base64 = x.stage[0].getStage().toDataURL();
        return {
          quadrant: x.quadrant,
          typeDent: x.typeDent,
          binaryImg: base64,
          base64: base64,
        }
      })

      this.imgQuadrants = stagesToSave;
    },
    buscarConvencion(dentNumber, faceCode) {
      const convencion = this.apliedConventions.find(item => {
        return item.faceInfo.dentNumber === dentNumber && item.faceInfo.faceCode === faceCode;
      });

      return convencion ? convencion.conventionInfo.name : '';
    },
    deleteConvention(dentNumber, faceCode) {
      const indexToRemove = this.apliedConventions.findIndex(item => item.faceInfo.dentNumber === dentNumber && item.faceInfo.faceCode === faceCode);
      if (indexToRemove !== -1) {
        this.apliedConventions.splice(indexToRemove, 1);
      }
    },
    async getConventions() {
      const response = await convencionesOdontogramaService.index();
      this.conventions = response.data;
    },
    async save() {
      try {
        this.LoaderSpinnerShow();

        this.downloadSVG();

        let dataOdontograma = {
          idHistoria: this.idHistoria,
          cariados:this.totalCariados,
          obturados:this.totalObturados,
          perdidos:this.totalPerdidos,
          sanos:this.totalSanos,
          indice:this.formatCount(this.totalCariados) + this.formatCount(this.totalObturados) + this.formatCount(this.totalPerdidos),
          apliedConventions: this.apliedConventions.map(x => {
            return {
              conventionId: x.conventionInfo.id,
              faceCode: x.faceInfo.faceCode,
              dentNumber: x.faceInfo.dentNumber
            }
          }),
        }

        const response = await historiaOdontogramaService.store(dataOdontograma);

        for (let index = 0; index < this.imgQuadrants.length; index++) {
          await historiaOdontogramaService.storeGraphics({
            idHistoria: this.idHistoria,
            idOdontograma: response.data.id,
            imgQuadrants: this.imgQuadrants[index],
          });
        }

        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con éxito'
        });
      } catch (error) {
        console.log(error);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al procesar la solicitud'
        });
      }
    },
    formatCount(count) {
      return count < 10 ? '0' + count : count;
    }
  },
  async mounted() {
    this.groupTeethsByQuadrant();
    this.getConventions();
    if (this.finalizada === 'N') {
      const response = await historiaOdontogramaService.showByHc(this.idHistoria);
      if (!isEmpty(response.data)) {
        this.apliedConventions = response.data.detalles.map(x => {
          return {
            conventionInfo: x.convencion,
            faceInfo: {
              dentNumber: x.dentNumber,
              faceCode: x.faceCode
            }
          }
        })
      }
    }
  },
  computed: {
    groupTheet() {

      const numerosDientesSet = new Set();

      this.apliedConventions.forEach(item => {
        numerosDientesSet.add(item.faceInfo.dentNumber);
      });
      // Convertir el Set de nuevo a un array para mantener la estructura de datos consistente
      const numerosDientes = Array.from(numerosDientesSet);

      const teeths = [];

      this.permanentTeeth.forEach(item => {
        if (numerosDientes.includes(item.number)) {
          teeths.push(item);
        }
      });

      this.temporaryTeeth.forEach(temporay => {
        if (numerosDientes.includes(temporay.number)) {
          teeths.push(temporay);
        }
      });

      return teeths;
    },
    totalCariados() {
      const dientesAgrupados = {};
      // Agrupar los dientes por número
      this.apliedConventions.forEach(diente => {
        const numeroDiente = diente.faceInfo.dentNumber;
        if (!dientesAgrupados[numeroDiente]) {
          dientesAgrupados[numeroDiente] = [];
        }
        dientesAgrupados[numeroDiente].push(diente);
      });

      // Contar los dientes con caries
      let count = 0;
      Object.values(dientesAgrupados).forEach(grupo => {
        if (grupo.some(diente => diente.conventionInfo.name === "cariado")) {
          count++;
        }
      });

      return count;
    },
    totalObturados() {
      const dientesAgrupados = {};
      // Agrupar los dientes por número
      this.apliedConventions.forEach(diente => {
        const numeroDiente = diente.faceInfo.dentNumber;
        if (!dientesAgrupados[numeroDiente]) {
          dientesAgrupados[numeroDiente] = [];
        }
        dientesAgrupados[numeroDiente].push(diente);
      });

      // Contar los dientes con caries
      let count = 0;
      Object.values(dientesAgrupados).forEach(grupo => {
        if (grupo.some(diente => diente.conventionInfo.name === "obturado")) {
          count++;
        }
      });

      return count;
    },
    totalPerdidos() {
      const dientesAgrupados = {};
      // Agrupar los dientes por número
      this.apliedConventions.forEach(diente => {
        const numeroDiente = diente.faceInfo.dentNumber;
        if (!dientesAgrupados[numeroDiente]) {
          dientesAgrupados[numeroDiente] = [];
        }
        dientesAgrupados[numeroDiente].push(diente);
      });

      // Contar los dientes con caries
      let count = 0;
      Object.values(dientesAgrupados).forEach(grupo => {
        if (grupo.some(diente => diente.conventionInfo.name === "ausente" || diente.conventionInfo.name === "extraccion indicada")) {
          count++;
        }
      });

      return count;
    },
    totalSanos() {
      return 28 - (this.totalCariados + this.totalObturados + this.totalPerdidos);
    }
  },
  watch: {
    finalizada: async function (value) {
      if (value === 'N') {
        const response = await historiaOdontogramaService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
          this.apliedConventions = response.data.detalles.map(x => {
            return {
              conventionInfo: x.convencion,
              faceInfo: {
                dentNumber: x.dentNumber,
                faceCode: x.faceCode
              }
            }
          })
        }
      }
    }
  }
};
</script>
<style scoped>
.number-column {
  width: 100px;
}

.face-column {
  width: 150px;
  height: auto;
}

.convention-column {
  width: 150px;
}
</style>