import Service from "./Service";
const baseurl = '/api/historia-sesiones-tratamiento';
export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByHc(id) {
        return Service.get(`${baseurl}/show-by-hc/${id}`);
    },
    showByIdUser(id) {
        return Service.get(`${baseurl}/show-by-usuario/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    showDetalleByIdTratamiento(id) {
        return Service.get(`${baseurl}/show-by-id-tratamiento/${id}`);
    },
    storeDetalleSesion(obj){
        return Service.post(`${baseurl}/store-detalle-sesion`, obj);
    },
    showDetalleByIdHistoria(id) {
        return Service.get(`${baseurl}/show-detalle-by-id-historia/${id}`);
    }

}