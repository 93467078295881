<template>
    <main>
        <div class="row mt-2">
            <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="(item) in $v.tejidos_blandos.$each.$iter" :key="item.id">
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck 
                            :antecedente="item" 
                            :label="item.$model.descripcion" 
                            :type="item.$model.tipo_campo.codigo"
                            :validator="item"
                            v-model="item.$model.tb_usuario.valor">
                        </buttonsCheck>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import buttonsCheck from '../baseButtonsCkeck/indexButtonsInverter';
import examenEstomatologicoService from '../../../../services/examenEstomatologicoService';
import examenEstomatologicoUsuarioService from '../../../../services/examenEstomatologicoUsuarioService';
import {required} from "vuelidate/lib/validators";
import { isEmpty } from 'lodash';
import Toast from "../../../../components/common/utilities/toast";

export default {
    props: ['embarazo_actual', 'usuario', 'idHistoria', 'finalizada', 'modo'],
    components: { buttonsCheck },
    data() {
        return {
            type: 'store',
            tejidos_blandos: []
        }
    },
    validations(){
        return {
            tejidos_blandos:{
                $each:{
                    tb_usuario:{
                        valor:{required}
                    },
                }
            }
        }
    },
    methods:{
        async save() {
            try {
                this.$v.$touch();
                if(this.$v.$invalid){
                    return;
                }

                let tejidos_blandos_usuario = [];

                this.tejidos_blandos.forEach((x) => {
                    if (x.tb_usuario.valor !== "") {
                        tejidos_blandos_usuario.push({ ...x.tb_usuario });
                    }
                });

                this.LoaderSpinnerShow();
                await examenEstomatologicoUsuarioService.storeTejidoUsuario({
                    tejidos_blandos_usuario: tejidos_blandos_usuario,
                });
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "success",
                    title: "Datos guardados con éxito",
                });
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }
        },
    },
    async created() {
        const filter = {
            sexo: this.usuario.sexo,
            fecha_nacimiento: this.usuario.fecha_nacimiento,
            ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? { gestante: 'S' } : {})
        };

        const response = await examenEstomatologicoService.indexTejidos({
            params: filter
        });

        const tejidos_blandos_usuario = await examenEstomatologicoUsuarioService.indexTejidoUsuario(
            this.usuario.id
        );

        this.tejidos_blandos = response.data.map((y) => {
            const indice = tejidos_blandos_usuario.data.findIndex(
                (a) => a.id_tejido === y.id
            );
            y.tb_usuario = {
                id: indice !== -1 ? tejidos_blandos_usuario.data[indice].id : 0,
                id_usuario: this.usuario.id,
                id_tejido: y.id,
                valor: indice !== -1 ? tejidos_blandos_usuario.data[indice].valor : "",
            };
            return y;
        });
    }

}
</script>