<template>
    <main>
        <div class="card card-header-actions shadow-none border-0" >
            <div class="card-body ">
                
                <div class="row">
                <div class="form-group col-md-2">
                    <label  class="form-label">Diente <small class="text-danger">*</small></label>
                    <select  v-model="item.diente" class="form-control" 
                    :class="{'is-invalid':$v.item.diente.$error && $v.item.diente.$dirty}">
                    <option :value="item.number" v-for="(item,index) in alldents" :key="index">{{item.number}}</option>
                </select>
                </div>
                 <div class="form-group col-md-8">
                    <label  class="form-label">Signos y Sintomas <small class="text-danger">*</small></label>
                    <textarea class="form-control form-control-sm"  v-model="item.sintomas" :class="{'is-invalid':$v.item.sintomas.$error && $v.item.sintomas.$dirty}" ></textarea>
                </div>
                <div class=" mt-4 py-2 col-md-2  text-center">
                    <button class="btn btn-primary btn-sm" @click="agregarExamen()">
                        <i class="fas fa-plus fa-fw"></i>Agregar
                    </button>
                </div>
                </div>
                
                <div class="table-responsive">
                    <table class="table table-borderless mb-0 table-sm">
                        <thead class="border-bottom bg-light">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Diente</th>
                                <th scope="col">Signos y Sintomas</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="border-bottom"
                                v-for="(item,index) in items" :key="item.id"
                            >
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.diente }}
                                    </div>
                                </td>
                                <td class="small text-muted">
                                    {{ item.sintomas }}
                                </td>
                                <td>
                                    <button class="btn btn-transparent rounded-circle"
                                        @click="removerExamen(index)">
                                        <i class="fas fa-trash-alt text-danger"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
    </main>
</template>
<script>
import examenEstomatologicoUsuarioService from '../../../../services/examenEstomatologicoUsuarioService';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';
import dientes from "@/assets/odontograma/odontogramaAdulto.json";

export default {
    props:['idHistoria','usuario','idTipoHistoria','finalizada'],
    data() {
        return{
            id_historia:'',
            dientesPermanentes:dientes.permanent,
            dientesTemporales:dientes.temp,
            item:{
                diente:'',
                sintomas:'',
            },
            items: [],
        }
    },
    validations (){
        return {
            item:{ 
               diente:{required},
               sintomas:{required}
            }
        }
    },
    methods: {
        agregarExamen() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({...this.item});
        },
        removerExamen(index) {
            this.items.splice(index, 1);
        },
        async save() {
            try {                
                const examen = {
                    id_historia: this.idHistoria,
                    examen_pulpar: this.items,
                };
                this.LoaderSpinnerShow();
                await examenEstomatologicoUsuarioService.storeExamenPulparUsuario(examen);
                this.limpiarCampos();
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });
            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ourrido un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos(){
            this.item={};
            this.items=[];
        },
    },
    async created(){
        if (this.finalizada === 'N') {
           const response = await examenEstomatologicoUsuarioService.examenPulparByHc(this.idHistoria);
            if (!isEmpty(response.data)) {
                this.items=response.data.map(x=>{
                    return {
                        diente:x.diente,
                        sintomas:x.sintoma
                    }
                });
            }
        }
    },
    computed:{
        alldents(){
            let diente=[];

            this.dientesPermanentes.forEach((e)=>{
                diente.push(e);
            });
            this.dientesTemporales.forEach((e)=>{
                diente.push(e);
            });

            return diente;
        }
    }
}
</script>
