import Service from "./Service";

const baseurl = '/api/examen-estomatologico';

export default {
    indexTejidos(pagination = {}, search = '') {

        let url = `${baseurl}/tejidos-blandos`;

        const hasAdditionalParams = !!pagination['params'];

        let additionalParams = {};

        if (hasAdditionalParams) {
            for (const param in pagination['params']) {
                if (
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ) {
                    additionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s: search} : {}),
            ...(pagination['per_page'] ? {per_page: pagination['per_page']} : {}),
            ...(pagination['page'] ? {page: pagination['page']} : {}),
            ...(hasAdditionalParams ? {...additionalParams} : {}),
        };

        return Service.get(url, {
            params: {
                ...params
            }
        });
    },
    indexBatm(pagination = {}, search = '') {

        let url = `${baseurl}/batm`;

        const hasAdditionalParams = !!pagination['params'];

        let additionalParams = {};

        if (hasAdditionalParams) {
            for (const param in pagination['params']) {
                if (
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ) {
                    additionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s: search} : {}),
            ...(pagination['per_page'] ? {per_page: pagination['per_page']} : {}),
            ...(pagination['page'] ? {page: pagination['page']} : {}),
            ...(hasAdditionalParams ? {...additionalParams} : {}),
        };

        return Service.get(url, {
            params: {
                ...params
            }
        });
    },
    indexAlteracionPeriodontal(pagination = {}, search = '') {

        let url = `${baseurl}/alteracion-periodontal`;

        const hasAdditionalParams = !!pagination['params'];

        let additionalParams = {};

        if (hasAdditionalParams) {
            for (const param in pagination['params']) {
                if (
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ) {
                    additionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s: search} : {}),
            ...(pagination['per_page'] ? {per_page: pagination['per_page']} : {}),
            ...(pagination['page'] ? {page: pagination['page']} : {}),
            ...(hasAdditionalParams ? {...additionalParams} : {}),
        };

        return Service.get(url, {
            params: {
                ...params
            }
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
}
