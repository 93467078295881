<template>
    <main>
        <label class="form-label">Analisis radiografico</label>
        <textarea cols="30" rows="5" class="form-control" v-model="analisis"></textarea>
    </main>
</template>
    
<script>
import Toast from "@/components/common/utilities/toast";
import { isEmpty } from "lodash";
import examenEstomatologicoUsuarioService from "@/services/examenEstomatologicoUsuarioService";

export default {
    props: ['idHistoria', 'idTipoHistoria', 'usuario', 'finalizada'],
    data() {
        return {
            analisis: ''
        }
    },
    methods: {
        async save() {
            try {

                const obj = {
                    id_historia: this.idHistoria,
                    analisis: this.analisis
                }

                this.LoaderSpinnerShow();

                await examenEstomatologicoUsuarioService.storeAnalisisRadiografico(obj);

                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });

            } catch (e) {
                console.log(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error al procesar la solicitud'
                });
            }

        }
    },
    watch: {
        finalizada: async function (value) {
            if (value === 'N') {
                const response = await examenEstomatologicoUsuarioService.analisisRadiograficoByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    this.analisis = response.data.analisis
                }
            }
        }
    },
    async created() {
        if (this.finalizada === 'N') {
            const response = await examenEstomatologicoUsuarioService.analisisRadiograficoByHc(this.idHistoria);

            if (!isEmpty(response.data)) {
                this.analisis = response.data.analisis
            }
        }

    }
}
</script>
   