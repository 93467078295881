<template>
    <main>
        <v-circle :config="config" ref="arcFaceOne" @click="onClick"></v-circle>
        <v-path 
        v-for="(convention, index) in conventions"
        :key="index"
        :config="convention" 
        />
    </main>
</template>

<script>
import Toast from '../../../../../components/common/utilities/toast';
import {isEmpty} from 'lodash';

export default {
    name: "faceOne",
    props:['config', 'teeth', 'appliedConventions'],
    methods : {
        onClick(){
            if(isEmpty(this.appliedConventions)){
                this.$emit('selected', {
                    faceCode : 'F1',
                    dentNumber : this.teeth.number,
                    teethInfo: this.teeth
                });
            } else if (!isEmpty(this.appliedConventions && this.appliedConventions[0].conventionInfo.type!=='all') ){ 
                this.$emit('selected', {
                    faceCode : 'F1',
                    dentNumber : this.teeth.number,
                    teethInfo: this.teeth
                });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Acción no permitida'
                });
            }
        }
    },
    computed:{
        conventions(){
            return this.appliedConventions.map(c => {
                const xAdjust = c.conventionInfo.type == 'all' ? c.conventionInfo.positionAdjustX : 6;
                const yAdjust = c.conventionInfo.type == 'all' ? c.conventionInfo.positionAdjustY : 6;
                return {
                    fill:c.conventionInfo.fill,
                    scaleX:c.conventionInfo.scaleX,
                    scaleY:c.conventionInfo.scaleY,
                    data : c.conventionInfo.svgPath,
                    x : this.$refs.arcFaceOne._props.config.x - xAdjust,
                    y : this.$refs.arcFaceOne._props.config.y - yAdjust
                }
            })
        }
    }
};
</script>

