<template>
    <main>
        <div class="row mt-2">
            <div class="list-group mb-3 col-lg-4 col-md-12 col-sm-12" v-for="(item) in $v.habitos_orales.$each.$iter" :key="item.id">
                <div class="list-group-item">
                    <div class="d-flex align-items-center justify-content-between">
                        <buttonsCheck 
                            :antecedente="item" 
                            :label="item.$model.descripcion" 
                            :type="item.$model.tipo_campo.codigo"
                            :validator="item"
                            v-model="item.$model.habito_usuario.valor">
                        </buttonsCheck>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import buttonsCheck from '../baseButtonsCkeck/indexButtons';
import habitosOralesService from '../../../../services/habitosOralesService';
import habitosOralesUsuarioService from '../../../../services/habitosOralesUsuarioService';
import {required} from "vuelidate/lib/validators";
import { isEmpty } from 'lodash';
import Toast from '../../../../components/common/utilities/toast';
export default {
    props: ['embarazo_actual', 'usuario', 'idHistoria', 'finalizada', 'modo'],
    components: { buttonsCheck },
    data() {
        return {
            type: 'store',
            habitos_orales: []
        }
    },
    validations(){
        return {
            habitos_orales:{
                $each:{
                    habito_usuario:{
                        valor:{required}
                    },
                }
            }
        }
    },
    methods:{
        async save() {
            try {
                this.$v.$touch();
                if(this.$v.$invalid){
                    return;
                }

                let habitos_orales_usuario = [];

                this.habitos_orales.forEach((x) => {
                    if (x.habito_usuario.valor !== "") {
                        habitos_orales_usuario.push({ ...x.habito_usuario });
                    }
                });

                this.LoaderSpinnerShow();
                await habitosOralesUsuarioService.store({
                    habitos_orales_usuario: habitos_orales_usuario,
                });
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con éxito'
                });
            } catch (error) {
                this.LoaderSpinnerHide();
                console.log(error);
                Toast.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error al procesar la solicitud'
                });
            }
        },
    },
    async created() {
        const filter = {
            sexo: this.usuario.sexo,
            fecha_nacimiento: this.usuario.fecha_nacimiento,
            ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? { gestante: 'S' } : {})
        };

        const response = await habitosOralesService.index({
            params: filter
        });

        const habitos_orales_usuario = await habitosOralesUsuarioService.index(
            this.usuario.id
        );

        this.habitos_orales = response.data.map((y) => {
            const indice = habitos_orales_usuario.data.findIndex(
                (a) => a.id_habito === y.id
            );
            y.habito_usuario = {
                id: indice !== -1 ? habitos_orales_usuario.data[indice].id : 0,
                id_usuario: this.usuario.id,
                id_habito: y.id,
                valor: indice !== -1 ? habitos_orales_usuario.data[indice].valor : "",
            };
            return y;
        });
    }

}
</script>