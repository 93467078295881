<template>
    <main>
        <v-rect :config="config" ref="arcFaceOne"></v-rect>
    </main>
</template>

<script>
export default {
    name: "faceOne",
    props: ['config', 'teeth'],
    methods: {
        onClick() {
            if (this.config.fill === "transparent") {
                this.config.fill = "red";
                this.$emit('selected', {
                    faceCode: 'F1',
                    dentNumber: this.teeth.number
                });
            } else if (this.config.fill === "red") {
                this.config.fill = "transparent";
                this.$emit('deselected', {
                    faceCode: 'F1',
                    dentNumber: this.teeth.number
                });
            }
           // console.log(this.$refs.arcFaceOne._props.config)
        }
    }
};
</script>

