<template>
    <main>
        <v-arc :config="config" ref="arcFaceThree" @click="onClick"></v-arc>
        <v-path 
        v-for="(convention, index) in conventions"
        :key="index"
        :config="convention" 
        />
    </main>
</template>

<script>
import {isEmpty} from 'lodash';
export default {
    name: "faceThree",
    props:['config', 'teeth', 'appliedConventions'],
    methods : {
        onClick(){
            if(isEmpty(this.appliedConventions)){
                this.$emit('selected', {
                    faceCode : 'F3',
                    dentNumber : this.teeth.number,
                    teethInfo: this.teeth
                });
            }

            console.log(this.$refs.arcFaceThree._props.config)
        }
    },
    computed:{
        conventions(){
            return this.appliedConventions.map(c => {
                return {
                    fill:c.conventionInfo.fill,
                    scaleX:c.conventionInfo.scaleX,
                    scaleY:c.conventionInfo.scaleY,
                    data : c.conventionInfo.svgPath,
                    x : this.$refs.arcFaceThree._props.config.x + 10,
                    y : this.$refs.arcFaceThree._props.config.y - 5,
                }
            })
        }
    }
};
</script>
