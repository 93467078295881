<template>
    <div>
        <v-arc :config="config" @click="onClick" ref="arcFaceFour"></v-arc>
        <v-path 
            v-for="(convention, index) in conventions"
            :key="index"
            :config="convention" 
            />
    </div>

</template>

<script>
import {isEmpty} from 'lodash';
export default {
    name: "faceFour",
    props:['config', 'teeth', 'appliedConventions'],
    methods : {
        onClick(){
            if(isEmpty(this.appliedConventions)){
                this.$emit('selected', {
                    faceCode : 'F4',
                    dentNumber : this.teeth.number,
                    teethInfo: this.teeth
                });
            
            }
            console.log(this.$refs.arcFaceFour._props.config)
        }
    },
    computed:{
        conventions(){
            return this.appliedConventions.map(c => {
                return {
                    fill:c.conventionInfo.fill,
                    scaleX:c.conventionInfo.scaleX,
                    scaleY:c.conventionInfo.scaleY,
                    data : c.conventionInfo.svgPath,
                    x : this.$refs.arcFaceFour._props.config.x - 5,
                    y : this.$refs.arcFaceFour._props.config.y + 10
                }
            })
        }
    }
};
</script>
