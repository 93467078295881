<template>
    <main>
        <v-rect :config="config" ref="arcFaceFive" @click="onClick"></v-rect>
    </main>
</template>

<script>
export default {
    name: "faceFive",
    props:['config', 'teeth','apliedFill'],
    methods : {
        onClick(){
            if (this.config.fill === "transparent") {
                this.config.fill = "red";
                this.$emit('selected', {
                    faceCode: 'F5',
                    dentNumber: this.teeth.number
                });
            } else if (this.config.fill === "red") {
                this.config.fill = "transparent";
                this.$emit('deselected', {
                    faceCode: 'F5',
                    dentNumber: this.teeth.number
                });
            }

            console.log(this.$refs.arcFaceFive._props.config)
        }
    },
    computed: {
        faceFill() {
            if (this.apliedFill.length > 0) {
                return 'red';
            } else {
                return 'transparent';
            }
        }
    },
    watch: {
        faceFill: {
            handler(newValue) {
                this.config.fill = newValue;
            },
            immediate: true
        }
    }
};
</script>

